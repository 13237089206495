import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
  },
  {
    path: "/article",
    name: "Article",
    component: () => import("../views/article"),
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/search"),
  },
  {
    path: "/article-info",
    name: "ArticleInfo",
    component: () => import("../views/article-info"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login"),
  },
];

const router = new VueRouter({
  // mode: "history",
  // base:"/wx/",
  mode: 'hash',
  routes,
});

export default router;
