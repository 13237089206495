import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import { DatetimePicker, Dialog, Popup, Field, Loading, Row, Col, NavBar, Swipe, SwipeItem, Pagination, PullRefresh, List, ImagePreview } from "vant"
import "vant/lib/index.css"
import "@vant/touch-emulator"
import "@/assets/styles/index.less"
// import VConsole from "vconsole";
import "animate.css/animate.css"

import Axios from "./api/axios"
Vue.use(DatetimePicker, Dialog, Popup, Field, Loading, Row, Col, NavBar, Swipe, SwipeItem, Pagination, PullRefresh, List, ImagePreview)
Vue.config.productionTip = false
Vue.prototype.$http = Axios

// eslint-disable-next-line no-unused-vars
// const vConsole = new VConsole()
new Vue({
    router,
    render: h => h(App)
}).$mount("#app")
